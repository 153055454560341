import FlashLib from 'flashlib_onlyplay';

export default class BaseCheckBox extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this._actions = {};
        this._enabled = false;
        this._checked = false;

        this.init();
        this.addListeners();

        this.enabled = false;
    }

    init() {
        this.interactive = true;
        this.cursor = 'pointer';
    }

    addListeners() {
        this.on('pointertap', this.onClick, this);
    }

    onEvent($data) {
        switch ($data.type) {
            case 'pointertap':
                this.onClick();
                break;
        }
    }


    onClick() {
        this.checked = !this.checked;
        let actionData = this.actions[this.checked ? 'checked' : 'unchecked'];
        if (actionData) {
            if (actionData.callback) {
                window.OPWrapperService.initConfig.onButtonClick();
                actionData.callback.call(actionData.context, actionData.params);
            }
        }
    }

    get actions() {
        return this._actions;
    }

    set actions(value) {
        this._actions = value;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value;
        if (value) {
            this.interactive = true;
            this.cursor = 'pointer';
        } else {
            this.interactive = false;
            this.cursor = null;
        }
    }

    get checked() {
        return this._checked;
    }

    set checked(value) {
        this._checked = value;
        this.goToFrame(this.checked ? 2 : 1);
    }
}
