export default function listenErrors (callback = () => {}) {
  function TS(){
    return (new Date).toLocaleString("sv", { timeZone: 'UTC' }) + "Z"
  }
  function findAndConvertErrorObjects(args = [], recursiveCount = 0) {
    if (recursiveCount > 5) return null;
    return args.reduce((prev, arg) => {
      if (arg && typeof(arg.message) === 'string' && arg.constructor && arg.constructor.name && /Error/.test(arg.constructor.name)) {
        return {
          message: arg.message,
          stack: arg.stack
        }
      } else if (arg && typeof(arg) === 'object') {
        return findAndConvertErrorObjects(Object.values(arg), recursiveCount++);
      } else {
        return null;
      }
    }, null);
  }
  const  addError = (type, value) => {
    const errorData = {
      type,
      timeStamp: TS(),
      value,
      userAgent: window.navigator.userAgent
    }
    let errorCode;
    try {
      errorCode = JSON.stringify(value).match(/code: \d*/);
    } catch (e) {
      errorCode = value && value.message && value.message.match(/code: \d*/);
    }
    if (errorCode) {
      errorCode = errorCode[0].replace('code: ', '');
    }
    callback(errorData, errorCode);
  }

  window.addEventListener('error', function (event) {
    const target = event.target;
    let errorDetails = {
      type: 'JSError'
    };

    if (
      target instanceof HTMLImageElement ||
      target instanceof HTMLScriptElement ||
      target instanceof HTMLLinkElement ||
      target instanceof HTMLVideoElement ||
      target instanceof HTMLAudioElement ||
      target instanceof HTMLIFrameElement
    ) {
      errorDetails.type = "resource";
      errorDetails.tagName = target.tagName;
      errorDetails.elementId = target.id;
      errorDetails.elementClassName = target.className;

      if (target instanceof HTMLImageElement || target instanceof HTMLScriptElement) {
        errorDetails.url = target.src;
      } else if (target instanceof HTMLLinkElement) {
        errorDetails.url = target.href;
      } else if (target instanceof HTMLVideoElement || target instanceof HTMLAudioElement) {
        errorDetails.url = target.currentSrc;
      } else if (target instanceof HTMLIFrameElement) {
        errorDetails.url = target.src;
      }

      errorDetails.message = "Resource failed to load";
    } else {
      const { message, filename, lineno, colno, error } = event;
      const stack = error ? error.stack : null;

      if (message) {
        errorDetails.message = message;
      }
      if (filename) {
        errorDetails.filename = filename;
      }
      if (typeof lineno === "number") {
        errorDetails.lineno = lineno;
      }
      if (typeof colno === "number") {
        errorDetails.colno = colno;
      }
      if (stack) {
        errorDetails.stack = stack;
      }
    }
    addError(errorDetails.type, errorDetails);
  }, true);

  window.addEventListener('unhandledrejection', function (e) {
    const reason = e.reason;
    addError("promiseRejection", {
      message: (reason && reason.message) ? reason.message : String(reason),
      stack: (reason && reason.stack) ? reason.stack : null
    });
  });

  const hookLogType = (logType) => {
    const original= console[logType].bind(console)
    return function(){
      let args = findAndConvertErrorObjects(Array.from(arguments));
      if (!args) {
        try {
          args = JSON.stringify(arguments);
        } catch (e) {

        }
      }
      addError(logType, args);
      original.apply(console, arguments)
    }
  }

  ['error'].forEach(logType=>{
    console[logType] = hookLogType(logType)
  })
}
