import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import ServerManager from '../server/ServerManager';

export default class RequestBonusState extends BaseState {
    constructor(stateData) {
        super(stateData);
        // this.onGetData = this.onGetData.bind(this);
    }

    async start() {
        super.start();
        // GlobalDispatcher.add('model:getServerData', this.onGetData);
        await ServerManager.onBuyBonus();
        this.complete();
    }

    onGetData(data) {
        if (data.params === 'buy_bonus') {
            this.complete();
        }
    }

    onEnd() {
        super.onEnd();
        GlobalDispatcher.remove('model:getServerData', this.onGetData);
    }
}
