import LocalizationManager from '../i18n/LocalizationManager';
import './rules.css';
import UserAgentDetector from '../utils/UserAgentDetector';
import { OP_WRAPPER_LOCALIZATION_PREFIX, SOCIAL_LANGUAGE_KEY } from '../utils/constants';
import { getUrlParam } from '../utils/url';

export default class ControllerRules {
  constructor(container, config) {
    this.config = config;
    this.container = container;

    this.basePdfUrl = 'https://res.onlyplay.net/rules';
    this.baseFrameUrl = 'https://pdf-viewer.onlyplay.net';
    this.fallbackLanguage = 'en';
    this.isOpened = false;

    this._render();
    this.init();

    window.OPWrapperService.sideBar.addButton({
      type: 'info',
      styles: ['rules'],
      onClick: () => this.isOpened ? this.close() : this.show()
    });

    LocalizationManager.addLocalizationChangedCallback(this.update.bind(this));
    this._stopSpin = this._stopSpin.bind(this);
  }

  _render() {
    this.container.insertAdjacentHTML('beforeend',
      `
      <div id="rulesWindow" class="wrapper_rules_container" style="display: none">
        <div class="window">
          <div class="buttonClose">×</div>
          <div class="rules_window_head">
            <div id="rules_title" class="title">
             ${this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.title`)}
            </div>
            <div class="link_box">
            <a id="rules_download_link" style="display: none"> ${this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.download`)}</a>
            </div>
          </div>
          <div id="iframe_rules_box" class="iframe_rules">
        </div>
       </div>
      </div>`);
  }

  init() {
    this.rulesWrapper = document.getElementById('rulesWindow');
    this.frameBox = document.getElementById('iframe_rules_box');

    this.buttonClose = this.rulesWrapper.getElementsByClassName('buttonClose')[0];
    this.buttonClose.addEventListener('click', this.close.bind(this));

    this.title = document.getElementById('rules_title');
    this.downloadLink = document.getElementById('rules_download_link');
  }

  update(lang) {
    this.config.lang = lang;
    this.updateTextFields();
  }

  updateTextFields() {
    this.title.innerText = this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.title`);
    this.downloadLink.innerText = this._getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.rules.download`);
  }

  async show() {
    if (this.isOpened) return;
    this.isOpened = true;

    window.scrollTo(0, 0);
    document.addEventListener('keydown', this._stopSpin, true);

    document.body.classList.add('op_wrapper__disableScroll');
    this.rulesWrapper.style.setProperty('display', 'flex');
    this.rulesWrapper.style.setProperty('pointer-events', 'all');

    await this._setFrame();
  }

  close() {
    if (!this.isOpened) return;
    this.isOpened = false;

    document.removeEventListener('keydown', this._stopSpin, true);
    document.body.classList.remove('op_wrapper__disableScroll');
    this.rulesWrapper.style.setProperty('display', 'none');
    this.rulesWrapper.style.setProperty('pointer-events', 'none');
    this.downloadLink.style.setProperty('display', 'none');
    window.OPWrapperService.initConfig.onButtonClick();

    this._removeFrame()
  }

  async _setFrame() {
    this.frame = document.createElement('iframe');
    this.frameBox.appendChild(this.frame);

    let pdfLink = this._getPdfLink();
    const available = await this._checkIsPdfAvailable(pdfLink);
    if (!this.frame) return; //!!!!

    if (!available) {
      pdfLink = this.config.lang === SOCIAL_LANGUAGE_KEY ? '' : this._getPdfLink(this.fallbackLanguage);
    }

    this.frame.src = this._getFrameLink(pdfLink);

    if (pdfLink && !window.OPWrapperService.config.hideRulesDownloadButton) {
      this.downloadLink.style.setProperty('display', 'block');
      this.downloadLink.setAttribute('href', pdfLink + '?download');
    }
  }

  _removeFrame() {
    this.frame.remove();
    this.frame = null;
  }

  _getLocalizedText(key) {
    const text = LocalizationManager.getLocalizedText(key);
    return text ? text : '';
  }

  _getPdfLink(lang) {
    const certification = window.OPWrapperService.config.certificationName;
    return `${this.basePdfUrl}/${this.config.bundle}/${certification}/${lang || this.config.lang}.pdf`;
  }

  _getFrameLink(pdfLink) {
    return `${this.baseFrameUrl}?url=${encodeURIComponent(pdfLink)}`;
  }

  async _checkIsPdfAvailable(url) {
    try {
      const response = await fetch(url, {
        method: 'HEAD'
      });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  _stopSpin(e) {
    if (e.keyCode !== 32) return;
    e.stopPropagation();
  }
}
