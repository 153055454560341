import queryString from 'querystring';
import ManagerHttp from './managerHttp';

export default class ManagerGameRequests {
  constructor({ api, sid, gid, cheatsParams, developmentGetSessionPath, baseData }) {
    this.api = api;
    this.sid = sid;
    this.gid = gid;
    this._baseData = baseData;
    this._cheatsParams = cheatsParams;
    this._developmentGetSessionPath = developmentGetSessionPath;

    this.adress = window.location.origin;
    if (this._developmentGetSessionPath) this._tryGetSessionForDev();
  }

  async sendGameInitRequest(data) {
    const response = await this.sendGameRequest(data, `game_init`);
    await window.OPWrapperService.init({
      partnerConfig: response.partner_config,
      language: response.lang,
      tournaments: response.tournaments,
      currencyInfo: {
        currency: response.currency,
        decimals: response.decimals,
      },
    });
    window.OPWrapperService.freeBetsController.setData(response.freebets);
    return response;
  }

  async sendGameRequest(data = {}, urlPath = 'game_request') {
    data = {
      ...this._baseData,
      ...data
    };
    this._trySendCheatParams(data);
    let url = `https://${ManagerHttp.api}/${urlPath}?sid=${ManagerHttp.sid}&gid=${ManagerHttp.gid}`;
    const retryCount = ['game_init', 'config'].includes(data.id) ? 2 : 0;
    try {
      return await ManagerHttp.postRequest(url, data,{}, retryCount);
    } catch (error) {
      if (error.hasOwnProperty('success') && !error.success) {
        window.OPWrapperService.showError(error.code, error.message, error.isReload);
      } else {
        console.error(error);
        window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
      }
    }
  }

  _tryGetSessionForDev() {
    const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
    if (devEnvironments.some(regexp => regexp.test(this.adress))) {
      const queryParams = queryString.parse(window.location.search.substr(1));
      if (!Object.keys(queryParams).length) {
        this._getSession();
      }
      this.adress = `https://${this.api}/`
    } else if (/192.168/.test(this.adress)) {
      this.adress = ''
    } else {
      this.adress = window.location.protocol + '//' + this.api + '/';
    }
  }

  async _getSession() {
    try {
      const raw = await fetch(this._developmentGetSessionPath, { //
        method: 'GET',
      });
      const response = await raw.json();
      if (response.url) {
        const searchParams = response.url.split('?')[1];
        window.location = `${window.location.origin}?${searchParams}`;
      }
    } catch (e) {
      console.error(e);
    }
  }

  _trySendCheatParams(data) {
    if (!this._cheatsParams) return;
    for (const cheatsParam of this._cheatsParams) {
      if (window[cheatsParam]) {
        data[cheatsParam] = window[cheatsParam];
        delete window[cheatsParam];
      }
    }
  }
}
