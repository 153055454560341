import { errorTypes } from '../utils/errorTypes';
import ManagerHttp from './managerHttp';

export default class ManagerBackgroundRequests {
  constructor(props) {
    this._checkStatusConfig = null;
    this._checkStatusTimer = null;
    this._checkStatusStartTime = null;

    this._startCheckSessionStatusTimer = this._startCheckSessionStatusTimer.bind(this);
    this.closeGame = this.closeGame.bind(this);

    window.onunload = this.closeGame;
  }

  async checkSessionStatus(inactivity_timeout) {
    const res = await ManagerHttp.postRequest(
      `https://${ManagerHttp.api}/check_session_status`,
      {
        sid: ManagerHttp.sid,
        gid: ManagerHttp.gid,
        inactivity_timeout,
      }
    ).catch(e => {
      window.OPWrapperService.showError(e.code, e.message);
      throw e;
    });
    if (res && res.status === 'closed') {
      window.OPWrapperService.showError(errorTypes.SESSION_EXPIRED.CODE, res.message)
    }
    return res;
  }

  async closeGame() {
    const data = {
      sid: ManagerHttp.sid,
      gid: ManagerHttp.gid,
      gameTitle: document.title,
      statistic: window.OPWrapperService.ControllerStatistic.statistic
    }
    window.OPWrapperService.eventManager.dispatch(
      window.OPWrapperService.eventManager.postMessageTypes.PMT_CLOSED,
      data
    );
    navigator.sendBeacon(
      `https://${ManagerHttp.api}/game_closed`,
      JSON.stringify(data)
    );
    return false;
  }

  _startCheckSessionStatusTimer() {
    clearInterval(this._checkStatusTimer)
    if (this._checkStatusConfig.checkStatusInterval) {
      this._checkStatusStartTime = Date.now();
      this._checkStatusTimer = setInterval(() => this.checkSessionStatus((Date.now() - this._checkStatusStartTime) / 1000), this._checkStatusConfig.checkStatusInterval * 1000)
    }
  }

  set statusCheckConfig(config) {
    this._checkStatusConfig = config;

    if (this._checkStatusConfig.checkStatus) {
      document.addEventListener('click', this._startCheckSessionStatusTimer);
      document.addEventListener('touchstart', this._startCheckSessionStatusTimer);
      this._startCheckSessionStatusTimer()
    }
  }

}
