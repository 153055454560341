import logo from './assets/logo.svg'

export default class BasePreloader {

  constructor() {
    this.createMarkup();
    this.createDelayedPreloaderMarkup();
    this.hidden = false;
    window.OPPreloader = this;
  }

  createMarkup() {
    document.body.insertAdjacentHTML('beforeend',
`<div id="preloader" class="preloader">
        <div class="preloaderContainer">
          <object data="${logo}" class="preloaderLogoBase preloaderLogo"></object>
          <div id="percents">0%</div>
          <div class="progressContainer">
            <div class="progressBg">
              <div id="progressBar" class="progress"></div>
            </div>
          </div>
        </div>
      </div>`);

    this.preloaderElement = document.getElementById('preloader');
    this.progressBar = document.getElementById('progressBar');
  }

  createDelayedPreloaderMarkup() {
    document.body.insertAdjacentHTML('beforeend',
      `
      <div id="delayedPreloader" class="delayedPreloader">
        <div class="preloaderLogo delayedLogo animLogo"></div>
      </div>
      `);

    this.delayedpPreloaderElement = document.getElementById('delayedPreloader');
  }

  showDelayedPreloader() {
    this.delayedpPreloaderElement.classList.add('show');
    document.addEventListener('keydown', this._stopSpin, true);
  }

  hideDelayedPreloader() {
    document.removeEventListener('keydown', this._stopSpin, true);
    this.delayedpPreloaderElement.classList.remove('show');
  }

  setSecondLogo(src) {
    this.preloaderElement.innerHTML =
      '  <div class="preloaderContainer preloaderContainer__forTwoLogo">\n' +
      '    <div id="preloaderSecondLogo"  class="preloaderLogoBase preloaderSecondLogo"></div>\n' +
      '    <div id="percents">0%</div>' +
      '    <div class="progressContainer progressContainer__forTwoLogo">\n' +
      '      <div class="progressBg">\n' +
      '        <div id="progressBar" class="progress"></div>\n' +
      '      </div>\n' +
      '    </div>\n' +
      '    <div class="preloaderLogoBase preloaderLogoProweredBy"></div>\n' +
      '  </div>\n';

    this.progressBar = document.getElementById('progressBar');
    this.secondLogo = document.getElementById('preloaderSecondLogo');
    this.secondLogo.style.background = ` center / contain url(${src}) no-repeat `;
  }

  hide({withFade, customDelay } = {}) {
    return new Promise((resolve) => {
      if (this.hidden) return;
      this.hidden = true;

      let delay = typeof customDelay === 'number' ? customDelay : 1000;

      if (withFade) {
        setTimeout(() => {
          this.preloaderElement.classList.add('fade');
        }, delay)
        delay += 500;
      } else {
      }

      console.log('Try hide');
      setTimeout(() => {
          this.onHide();
          this.preloaderElement.classList.remove('fade');
          this.preloaderElement.classList.add('hide');
          resolve();
        },
        delay);
    })
  }

  setProgress(value) {
    const percents = document.getElementById('percents');
    percents.innerText = `${value.toFixed()}%`;
    const maxWidth = parseFloat(window.getComputedStyle(this.progressBar).maxWidth);
    this.progressBar.style.width = value * maxWidth / 100 + '%';
  }

  startProgressLabel() {
    let value = 0;
    const intervalDuration = 50;
    const duration = 3000;
    const stepsCount = duration / intervalDuration;
    const step = 98 / stepsCount;
    const percents = document.getElementById('percents');
    const interval = setInterval(() => {
      if (value > 99) {
        clearInterval(interval);
        return;
      }

      percents.innerText = `${value.toFixed()}%`;
      value += step;
    }, intervalDuration)
  }
  onHide() {
    if (window.OPWrapperService &&
      window.OPWrapperService.__proto__.hasOwnProperty('showStartPopups') &&
      typeof window.OPWrapperService.showStartPopups === 'function') {
      window.OPWrapperService.showStartPopups();
    }
  }
  _stopSpin(e) {
    if (e.keyCode !== 32) return;
    e.stopPropagation();
  }
}
