export default class FontsLoader {
  /**
   * @param fontsData - array of objects {name: fontName, path: pathToFont}
   * @return Promise when all fonts loaded
   */
  static load(fontsData) {
    const fontLoaders = fontsData.map(data => FontsLoader.loadOnce(data.name, data.path));
    return Promise.all(fontLoaders);
  }

  static loadOnce(name, path) {
    return new Promise((resolve, reject) => {
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Font loading timed out')), 5000)
      );

      const font = new FontFace(name, `url(${path})`);

      const loadPromise = font.load();

      Promise.race([loadPromise, timeoutPromise])
        .then((loaded_face) => {
          document.fonts.add(loaded_face);
          resolve();
        }).catch((err) => {
        console.error(`Font "${name}" can't be loaded from "${path}"`);
        console.error(err);
        resolve();
      });

    });
  }
}
